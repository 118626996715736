import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { H2, H3 } from "../components/Heading"
import { LAYOUT } from "../constants"

const WHATS_IN_IT_DATA = [
  {
    imgKey: "feelGood",
    heading: (
      <>
        Feel good
        <br /> factor
      </>
    ),
    description: "Your donation makes a huge difference",
  },
  {
    imgKey: "bodySoul",
    heading: (
      <>
        Good for the
        <br /> body & soul
      </>
    ),
    description: "There really are no downsides to staying active",
  },
  {
    imgKey: "runnersRewards",
    heading: (
      <>
        Runners (& movers)
        <br /> get rewards
      </>
    ),
    description: "Get treats from some of our epic partners",
  },
]

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const WhatsInItBox = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 20px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    width: calc(33% - 20px);
    margin-bottom: 0;
  }
`

const BoxDescription = styled(BodyText)`
  a,
  p {
    font-size: 1.6rem;
    text-align: center;
  }
`

interface IWhatsInItBoxImg {
  fixed: object
}

const WhatsInItBoxImg = styled(Img)<IWhatsInItBoxImg>`
  width: 172px;
  margin-bottom: 30px;
`

const BoxHeading = styled(H3)`
  margin-bottom: 15px;
`

const SubHeading = styled(H2)`
  margin-bottom: 30px;
`

const WrapperInner = styled.div`
  max-width: 908px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const WhatsInItBoxesContainer = styled.div`
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`

interface IWhatsInItForYou {
  rest?: object
}

const WhatsInItForYou: React.FC<IWhatsInItForYou> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query WhatsInItForYouQuery {
      feelGood: file(
        relativePath: { eq: "whatsInItForYou/feel-good-factor.png" }
      ) {
        childImageSharp {
          fixed(width: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      bodySoul: file(
        relativePath: { eq: "whatsInItForYou/body-and-soul.png" }
      ) {
        childImageSharp {
          fixed(width: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      runnersRewards: file(
        relativePath: { eq: "whatsInItForYou/runners-rewards.png" }
      ) {
        childImageSharp {
          fixed(width: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Root {...rest}>
      <WrapperInner>
        <SubHeading>What’s in it for you?</SubHeading>
        <WhatsInItBoxesContainer>
          {WHATS_IN_IT_DATA.map(data => (
            <WhatsInItBox key={data.description}>
              <WhatsInItBoxImg
                fixed={imgData[data.imgKey].childImageSharp.fixed}
                alt={data.description}
              />
              <BoxHeading alignment={"CENTER"} color={"GREY"}>
                {data.heading}
              </BoxHeading>
              <BoxDescription type={"DARK"}>
                <p>{data.description}</p>
              </BoxDescription>
            </WhatsInItBox>
          ))}
        </WhatsInItBoxesContainer>
      </WrapperInner>
    </Root>
  )
}

export default WhatsInItForYou
