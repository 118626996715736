import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { H2 } from "../components/Heading"
import { LAYOUT } from "../constants"

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    padding: 60px 0 80px 0;
  }
`

const WrapperInner = styled.div`
  max-width: 908px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledBodyText = styled(BodyText)`
  margin-bottom: 40px;
  max-width: 670px;
`

interface IMrBrainImg {
  fluid: object
}

const MrBrainImg = styled(Img)<IMrBrainImg>`
  max-width: 333px;
  width: 100%;
  margin-bottom: 30px;
`
interface ISpreadingTheLove {
  rest?: object
}

const SpreadingTheLove: React.FC<ISpreadingTheLove> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query SpreadingTheLoveQuery {
      mrBrain: file(relativePath: { eq: "spreadingTheLove/mr-brain.png" }) {
        childImageSharp {
          fluid(maxWidth: 333, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <WrapperInner>
        <MrBrainImg
          fluid={imgData.mrBrain.childImageSharp.fluid}
          alt={"Mindful brain"}
        />
        <StyledBodyText type={"LIGHT"} alignment={"CENTER"}>
          <p>
            We are big advocates that going for a run or walk can be incredibly
            beneficial for our mental wellbeing.
          </p>

          <p>
            That's why once again we're asking you to unite as a team or
            individual, and raise money for charity across the UK.
          </p>
        </StyledBodyText>

        <H2 alignment={"CENTER"} color={"WHITE"}>
          It's all about spreading the love!
        </H2>
      </WrapperInner>
    </Root>
  )
}

export default SpreadingTheLove
