import React from "react"
import styled from "styled-components"

import Button from "../components/Button"
import BodyText from "../components/BodyText"
import fiveKChallengeImage from "../assets/img/hero/5k-challenge.png"
import fiveKChallengeImage2x from "../assets/img/hero/5k-challenge@2x.png"
import { LAYOUT, LINKS } from "../constants"

const Root = styled.div`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
`

const FivekImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${fiveKChallengeImage});
  width: 100%;
  background-position: center;
  height: 200px;
  margin-bottom: 35px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    height: 486px;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${fiveKChallengeImage2x});
  }
`

const HeroContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: unset;
    flex-direction: column;
    align-items: center;
  }
`

const SummaryDescription = styled(BodyText)`
  max-width: 568px;
  padding: 0 20px;
  margin: 0 auto 50px auto;
  p,
  a {
    font-size: 1.6rem;
  }
`

const StyledButton = styled(Button)`
  margin-bottom: 20px;
  align-self: center;
  margin-right: 10px;
  margin-left: 10px;
  width: 200px;
  justify-content: center;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    width: 150px;
  }
`

interface IHero {
  rest?: object
}

const FiveKMayHero: React.FC<IHero> = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <HeroContainer>
        <FivekImage />

        <StyledButton to={"/sign-up"}>Sign up</StyledButton>
        <SummaryDescription type={"LIGHT"} alignment={"CENTER"}>
          <p>
            Already participated?{" "}
            <a href={LINKS.DONATE} target={"_blank"} rel={"noopener"}>
              Donate now
            </a>
          </p>
        </SummaryDescription>
      </HeroContainer>
    </Root>
  )
}

export default FiveKMayHero
