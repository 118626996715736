import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Button from "../components/Button"
import Divider from "../components/Divider"
import BodyText from "../components/BodyText"
import { H2, H3 } from "../components/Heading"
import { LAYOUT, LINKS } from "../constants"

const HOW_TO_DATA = [
  {
    imgKey: "participate",
    heading: "Participate",
    description: "Run, walk, roll your 5k, do whatever suits you",
  },
  {
    imgKey: "donate",
    heading: "Donate",
    description: (
      <>
        <a href={LINKS.DONATE} target={"_blank"} rel={"noopener"}>
          Donate £5
        </a>{" "}
        to a cause close to your heart.
      </>
    ),
  },
  {
    imgKey: "share",
    heading: "Nominate",
    description: (
      <>
        Nominate your friends on <a href={LINKS.INSTAGRAM}>social media</a> to
        take part too
      </>
    ),
  },
  {
    imgKey: "rewards",
    heading: "Rewards",
    description: (
      <>
        Runners & movers get rewards from our{" "}
        <a href={"/our-partners/corporate"}>exclusive partners</a>.
      </>
    ),
  },
]

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  align-self: center;
  margin-bottom: 15px;
  height: 62px;
  margin: 0 5px 25px 5px;
`

const HowToBoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 60px;
`

const HowToBox = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 20px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    width: calc(25% - 20px);
    margin-bottom: 0;
  }
`

const WhatsInItBox = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 20px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    width: calc(33% - 20px);
    margin-bottom: 0;
  }
`

const BoxDescription = styled(BodyText)`
  a,
  p {
    font-size: 1.6rem;
    text-align: center;
  }
`

interface IWhatsInItBoxImg {
  fixed: object
}

const BoxHeading = styled(H3)`
  margin-bottom: 15px;
`

const HowToHeading = styled(H2)`
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 60px;
  }
`

const SubHeading = styled(H2)`
  margin-bottom: 30px;
`

const CtaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const AlreadyParticipated = styled(BodyText)`
  width: 100%;
  margin-bottom: 40px;
`

const WrapperInner = styled.div`
  max-width: 908px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const WhatIs5kBodyText = styled(BodyText)`
  margin-bottom: 40px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 65px;
  }
`

interface IWhatIs5kMay {
  rest?: object
}

const WhatIs5kMay: React.FC<IWhatIs5kMay> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query WhatIs5kMayQuery {
      participate: file(relativePath: { eq: "boxes/participate.png" }) {
        childImageSharp {
          fixed(width: 244, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      donate: file(relativePath: { eq: "boxes/donate.png" }) {
        childImageSharp {
          fixed(width: 244, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      share: file(relativePath: { eq: "boxes/share.png" }) {
        childImageSharp {
          fixed(width: 244, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      rewards: file(relativePath: { eq: "boxes/rewards.png" }) {
        childImageSharp {
          fixed(width: 244, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <WrapperInner>
        <SubHeading>What is 5kMay?</SubHeading>
        <WhatIs5kBodyText type={"DARK"} alignment={"CENTER"}>
          <p>
            One month of coming together for better! You can do your 5k
            anywhere, anytime with anyone. You can run, roll, walk, cycle or
            even swim if you want to… It’s about getting out and feeling good
            while you’re doing it - all whilst giving back to charity.
          </p>
        </WhatIs5kBodyText>
      </WrapperInner>

      <Divider />

      <HowToHeading alignment={"CENTER"}>How to get involved</HowToHeading>
      <HowToBoxesContainer>
        {HOW_TO_DATA.map(data => (
          <HowToBox key={data.heading}>
            <Img fixed={imgData[data.imgKey].childImageSharp.fixed} />
            <BoxHeading alignment={"CENTER"} color={"GREY"}>
              {data.heading}
            </BoxHeading>
            <BoxDescription type={"DARK"}>
              <p>{data.description}</p>
            </BoxDescription>
          </HowToBox>
        ))}
      </HowToBoxesContainer>

      <CtaContainer>
        <StyledButton color={"WHITE_BORDERED"} to={"/sign-up"}>
          Get involved as a team
        </StyledButton>
        <StyledButton color={"GREY"} to={"/sign-up"}>
          Sign up as an individual
        </StyledButton>
        <AlreadyParticipated type={"DARK"} alignment={"CENTER"}>
          <p>
            Already participated?{" "}
            <a href={LINKS.DONATE} target={"_blank"} rel={"noopener"}>
              Donate now
            </a>
          </p>
        </AlreadyParticipated>
      </CtaContainer>
    </Root>
  )
}

export default WhatIs5kMay
