import React from "react"
import { Helmet } from "react-helmet"

import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import FiveKMayHero from "../partials/FiveKMayHero"
import SpreadingTheLove from "../partials/SpreadingTheLove"
import OurPartners from "../partials/OurPartners"
import WhatsInItForYou from "../partials/WhatsInItForYou"
import WhatIs5kMay from "../partials/WhatIs5kMay"
import AsSeenIn from "../partials/AsSeenIn"
import OurNextEvent from "../partials/OurNextEvent"
import Footer from "../partials/Footer"
import CookieBanner from "../partials/CookieBanner"
import "../styles.css"

const FiveKChallenge: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>5k May | Get Involved | Run For Heroes</title>
        <meta
          name="description"
          content="Take part our next challenge, 5kMay. Get involved as a team or individual - the perfect opportunity to get fit and give back this May!"
        />
        <html lang="en" />
      </Helmet>
      <Nav activePath={"/5k-may"} />
      <PageSection noPadding backgroundColor={"BLUE"}>
        <FiveKMayHero />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <WhatIs5kMay />
      </PageSection>
      <PageSection backgroundColor={"GREEN"}>
        <SpreadingTheLove />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <OurNextEvent />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <WhatsInItForYou />
      </PageSection>
      <PageSection backgroundColor={"PINK"}>
        <OurPartners />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <AsSeenIn />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default FiveKChallenge
